/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlloyIntegrationType {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  GORGIAS = "GORGIAS",
  KLAVIYO = "KLAVIYO",
  SLACK = "SLACK",
}

export enum ClientFeatureState {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  LIMITED = "LIMITED",
  NEW_ONLY = "NEW_ONLY",
}

export enum ContentFieldEnum {
  DATE = "DATE",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  NUMBER = "NUMBER",
  SINGLE_SELECT = "SINGLE_SELECT",
  TEXT = "TEXT",
  URL = "URL",
}

export enum ContentType {
  ADDITIONAL_IMAGES = "ADDITIONAL_IMAGES",
  ADDITIONAL_VIDEOS = "ADDITIONAL_VIDEOS",
  BLOG_DEDICATED = "BLOG_DEDICATED",
  BLOG_MENTION = "BLOG_MENTION",
  FACEBOOK_POST = "FACEBOOK_POST",
  FLEXIBLE = "FLEXIBLE",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_POST = "INSTAGRAM_POST",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  INSTAGRAM_VIDEO = "INSTAGRAM_VIDEO",
  OTHER = "OTHER",
  PINTEREST = "PINTEREST",
  PINTEREST_PIN = "PINTEREST_PIN",
  SNAPCHAT_STORY = "SNAPCHAT_STORY",
  TIKTOK_VIDEO = "TIKTOK_VIDEO",
  TWITTER_POST = "TWITTER_POST",
  YOUTUBE_DEDICATED = "YOUTUBE_DEDICATED",
  YOUTUBE_MENTION = "YOUTUBE_MENTION",
  YOUTUBE_SHORT = "YOUTUBE_SHORT",
}

export interface AffiliateOfferPromoInputType {
  desiredPromoCode: string;
  memberId: number;
  payoutId?: number | null;
}

export interface AuthorizedAgentInput {
  authorizedAgentId: string;
  userSignature: UserSignatureInput;
}

export interface EmbeddedReportingItemInput {
  id?: number | null;
  name: string;
  position: number;
  url: string;
}

export interface FindContentFieldsInput {
  clientId?: string | null;
  fieldTypes?: ContentFieldEnum[] | null;
  postTypes?: ContentType[] | null;
  programIds?: number[] | null;
}

export interface InstallApplicationInput {
  applicationId: string;
}

export interface SaveApplicationInput {
  icon?: string | null;
  name: string;
}

export interface SaveClientFeatureInput {
  description: string;
  id?: number | null;
  name: string;
  state: ClientFeatureState;
}

export interface SaveClientGroupInput {
  id?: number | null;
  name?: string | null;
  organizationId: number;
}

export interface SaveClientInput {
  clientGroupId: number;
  disabledFeatureIds?: number[] | null;
  domains?: string[] | null;
  featureIds?: number[] | null;
  forceActive?: boolean | null;
  hostname?: string | null;
  id?: string | null;
  isTestClient?: boolean | null;
  memberDomainsRestricted?: boolean | null;
  name: string;
  pfaLocationIds?: string[] | null;
  pfaOrderTag?: string | null;
  salesForceId?: string | null;
}

export interface SaveOrganizationInput {
  id?: number | null;
  name: string;
}

export interface SaveUserInput {
  clientIds: string[];
  email: string;
  featureIds: number[];
  id?: string | null;
  isAdmin?: boolean | null;
  name?: string | null;
}

export interface UpsertContentFieldInput {
  canMarkNa?: boolean | null;
  clientId: string;
  description?: string | null;
  fieldType: ContentFieldEnum;
  isMandatory?: boolean | null;
  name: string;
  postTypes?: ContentType[] | null;
  programIds?: number[] | null;
  selectOptions?: string[] | null;
}

export interface UserSignatureInput {
  signatureText?: string | null;
  title?: string | null;
  useFullNameForSignature?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import React, { useMemo, useEffect, MouseEvent } from 'react';
import { Drawer, Form, Input, Select, Switch, Row, Col, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { ContentType, ContentFieldEnum } from '@frontend/app/types/globalTypes';
import TextArea from 'antd/lib/input/TextArea';
import styles from './ContentFieldDrawer.scss';
import { ContentFieldsFormValue } from '../types';
import { GetProgramsQuery_programs } from '@frontend/app/queries/types/GetProgramsQuery';

const { Option } = Select;

type IOption = {
  value: string | number | ContentType;
  label?: string;
};

interface IProps {
  formData: ContentFieldsFormValue;
  onClose: () => void;
  visible: boolean;
  onFinish: (values: ContentFieldsFormValue) => void;
  onFormChange: (values: ContentFieldsFormValue) => void;
  form: FormInstance;
  creatingContentField: boolean;
  updatingContentField: boolean;
  loadingContentFields: boolean;
  onDelete: (e: MouseEvent<HTMLButtonElement>, formData: ContentFieldsFormValue) => void;
  programs: GetProgramsQuery_programs[];
}

const ContentFieldDrawer = ({
  formData,
  onClose,
  visible,
  onFinish,
  onFormChange,
  form,
  creatingContentField,
  updatingContentField,
  loadingContentFields,
  onDelete,
  programs,
}: IProps) => {
  const contentFieldOptions: IOption[] = useMemo(
    () =>
      Object.entries(ContentFieldEnum)
        ?.map(
          ([key, value]) =>
            ({
              value,
              label: key
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            } as IOption),
        )
        .sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? '')),
    [],
  );

  const contentTypeOptions: IOption[] = useMemo(
    () =>
      Object.entries(ContentType)
        ?.map(
          ([key, value]) =>
            ({
              value,
              label:
                value === ContentType.INSTAGRAM
                  ? 'Instagram Post'
                  : key
                      .replace(/_/g, ' ')
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase()),
            } as IOption),
        )
        ?.filter(
          (obj) =>
            ![
              ContentType.INSTAGRAM_POST,
              ContentType.BLOG_DEDICATED,
              ContentType.BLOG_MENTION,
              ContentType.SNAPCHAT_STORY,
            ].includes(obj.value as ContentType),
        )
        .sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? '')),
    [],
  );

  const projectOptions = useMemo(
    () =>
      programs
        ?.map((program: GetProgramsQuery_programs) => ({
          value: program.id,
          label: program.title,
        }))
        .sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? '')),
    [programs],
  );

  useEffect(() => {
    const sanitizedFormData = {
      ...formData,
      selectOptions: Array.isArray(formData.selectOptions) ? formData.selectOptions : [],
      postTypes: Array.isArray(formData.postTypes) ? formData.postTypes : [],
    };
    form.setFieldsValue(sanitizedFormData);
  }, [formData, form]);

  return (
    <Drawer
      title={formData.name}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
      className={styles.contentFieldsDrawer}
      footer={
        <div className={styles.footer}>
          <div>
            {formData.id && (
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  onDelete(e as MouseEvent<HTMLButtonElement>, formData);
                  onClose();
                }}
              />
            )}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={creatingContentField || updatingContentField || loadingContentFields}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      {/* @ts-ignore */}
      <Form layout="vertical" form={form} initialValues={formData} onValuesChange={onFormChange} onFinish={onFinish}>
        <Form.Item
          label="Enter a name for your field"
          name="name"
          rules={[{ required: true, message: 'Please enter a field name' }]}
        >
          <Input placeholder="Which retailer is this for?" />
        </Form.Item>

        <Form.Item
          label="Field Caption (optional)"
          name="description"
          help={form.getFieldError('name').length > 0 ? form.getFieldError('name')[0] : ''}
          validateStatus={form.getFieldError('name').length > 0 ? 'error' : ''}
        >
          <TextArea placeholder="Enter a caption to appear below the content field" maxLength={200} rows={3} />
        </Form.Item>

        <Form.Item
          label="Field Type"
          name="fieldType"
          rules={[{ required: true, message: 'Please select a field type' }]}
        >
          <Select placeholder="Select Field Type" disabled={!!formData?.id}>
            {contentFieldOptions?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.value === 'URL' ? 'URL' : option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {(formData.fieldType === ContentFieldEnum.SINGLE_SELECT ||
          formData.fieldType === ContentFieldEnum.MULTIPLE_SELECT) && (
          <Form.Item
            label="Options"
            name="selectOptions"
            rules={[
              {
                validator: (_, value) => {
                  // Check if value is an array and contains at least one non-null value
                  if (
                    Array.isArray(value) &&
                    value.some((option) => option !== null && option !== undefined && option !== '')
                  ) {
                    return Promise.resolve(); // Valid
                  }
                  return Promise.reject(new Error('At least one option must be provided.')); // Invalid
                },
              },
            ]}
          >
            <Form.List name="selectOptions">
              {(fields = [], { add, remove }) => {
                const handleAdd = () => {
                  add();
                  onFormChange(form.getFieldsValue() as ContentFieldsFormValue);
                };

                const handleRemove = (index: number) => {
                  remove(index);
                  onFormChange(form.getFieldsValue() as ContentFieldsFormValue);
                };

                return (
                  <>
                    <>
                      {fields?.map((field, index) => (
                        <div key={field.key} className={styles.optionContainer}>
                          <Form.Item name={[index]}>
                            <Input
                              placeholder="Enter new option"
                              suffix={<CloseOutlined onClick={() => handleRemove(index)} />}
                              type="text"
                              onChange={(e) => {
                                const updatedOptions = [...form.getFieldValue('selectOptions')];
                                updatedOptions[index] = e.target.value;
                                form.setFieldsValue({ selectOptions: updatedOptions });
                                onFormChange(form.getFieldsValue() as ContentFieldsFormValue);
                              }}
                            />
                          </Form.Item>
                        </div>
                      ))}
                    </>
                    <Button type="dashed" onClick={handleAdd} block>
                      + Add Option
                    </Button>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        )}
        <Form.Item
          label="Projects"
          name="programIds"
          rules={[{ required: true, message: 'Please select atleast one project' }]}
        >
          <Select
            placeholder="Select Project"
            mode="multiple"
            allowClear
            options={projectOptions}
            filterOption={(input, option) => String(option?.label).toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
        <Form.Item label="Post Type" name="postTypes">
          <Select placeholder="Select Post Type(s)" mode="multiple" allowClear>
            {contentTypeOptions &&
              contentTypeOptions?.map((option: IOption) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Required Field">
          <Row gutter={[0, 16]} style={{ alignItems: 'center' }}>
            <Col>
              <Form.Item name="isMandatory" valuePropName="checked" noStyle>
                <Switch size="small" disabled={!!formData?.id} />
              </Form.Item>
              <span style={{ marginLeft: 10 }}>Require creators to fill out field</span>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={{ alignItems: 'center' }}>
            <Col>
              <Form.Item name="canMarkNa" valuePropName="checked" noStyle>
                <Switch size="small" disabled={!!formData?.id} />
              </Form.Item>
              <span style={{ marginLeft: 10 }}>Allow creators to mark field as &quot;N/A&quot;</span>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ContentFieldDrawer;
